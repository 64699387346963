.invalid {
  border: 1px solid red;
  padding-left: 50px;
  height: 40px;
  border-radius: 8px;
}

.invalid:focus {
  border: 1px solid red;
  box-shadow: none;
}

.register-form {
  padding-left: 50px;
  height: 40px;
  border-radius: 8px;
}

.form-readonly {
  padding-left: 50px;
  height: 40px;
  border-radius: 8px;
}

.register-form:focus {
  border: 2px solid #A3C6F1;
  box-shadow: none;
}

.form-readonly:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}

.register-link {
  color: #0084f3;
}

.register-link:hover {
  color: #0084f3;
  text-decoration: underline !important;
}
