.container-total {
  position: relative;
  padding: 4% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75vh;
  justify-content: space-between;
  color: #516373;
}

.container-verify {
  width: 100%;
  text-align: center;
  position: relative;
}

.container-link {
  width: 290px;
  text-align: center;
  position: relative;
}

.verification-code {
  position: relative;
  width: 100%;
}

.button-verify {
  background-color: #516272;
  border-radius: 5px;
  color: #ffffff;
  width: 290px;
  height: 51px;
  border: none;
}

.button-verify:hover {
  background-color: #424b53;
}

.button-verify:enabled:active {
  transform: translateY(2px);
}

.button-verify:disabled {
  background-color: #00000066;
  border-radius: 5px;
  color: #ffffff;
  width: 290px;
  height: 51px;
  border: none;
  cursor: not-allowed;
}

.link-text {
  color: #516272;
  cursor: pointer;
  text-decoration: underline !important;
  border: none;
  background-color: transparent;
  padding: 0%;
}

.link-text:hover {
  color: #424b53;
}

.link-text-disable {
  color: #d1d1d1;
  text-decoration: underline !important;
  border: none;
  background-color: transparent;
  padding: 0%;
  cursor: not-allowed;
}

.Vtimer {
  color: #d1d1d1;
  text-decoration: underline;
}

.incorrect-otp {
  padding: 1%;
  font-size: 14px;
  text-align: center;
  color: #ff892e;
}
