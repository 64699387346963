.sidemenu-div a {
  color: #b5c1d1 !important;
  padding: 15px 25px 15px 25px;
  display: block;
}

.sidemenu-div a.active {
  color: #516373 !important;
}

.sidemenu-div a,
.sidemenu-div a:hover,
.sidemenu-div a:active {
  outline: none !important;
  border: none !important;
  color: #b5c1d1;
  text-decoration: none !important;
}

.no-ver-email {
  display: flex;
  background-color: #dc0505;
  color: #ffffff;
  font-size: 10pt;
  border-radius: 4pt;
  border: none;
  width: 70pt;
  height: 20pt;
  justify-content: center;
  align-items: center;
}

.fs-15 {
  font-size: 15px !important;
}