@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@400;600;700&family=Noto+Sans:wght@400;700&display=swap');

html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans Thai', 'Noto Sans', sans-serif;
    font-size: 18px;
    scroll-behavior: smooth;
}

.object-cover {
    object-fit: cover;
}
.mh-40vw {
    max-height: 40vw;
}

.brand_name {
    color: #506070;
}

a {
    text-decoration: none !important;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: 600;
}

.text-sm {
    font-size: 0.95rem;
}

.no-padding{
    padding: 0px
}

.modal-dialog-scrollable .modal-body {
    max-height: 75vh;
    overflow-y: auto;
}

.modal {
    height: 100% !important;
}


.fa-rotate-45 {
    transform: rotateZ(-45deg)
}

.terms_conditions > ol{ 
    margin: 0;
    font-size: 13px;
}

.terms_conditions > ul{ 
    margin: 0;
    font-size: 13px;
}

.terms_conditions > blockquote{ 
      margin: 0;
    font-size: 13px;

}

.terms_conditions > p{ 
    margin: 0;
    font-size: 13px;
}
  
.terms_conditions > h1{ 
    margin: 0;
    font-size: 2rem;
}
  
.terms_conditions > h2{ 
    margin: 0;
    font-size: 1.5rem;
}
  
.terms_conditions > h3{ 
    margin: 0;
    font-size: 1.17rem;
}

@media only screen and (max-width: 990px) {
    .terms_conditions > p{ 
        margin: 0;
        font-size: 12px;
    }
      
    .terms_conditions > h1{ 
        margin: 0;
        font-size: 1.6rem;
    }
      
    .terms_conditions > h2{ 
        margin: 0;
        font-size: 1.2rem;
    }
      
    .terms_conditions > h3{ 
        margin: 0;
        font-size: 0.95rem;
    }
}


::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
  
::-webkit-scrollbar-track {
    border-radius: 15px;
}
   
::-webkit-scrollbar-thumb {
    background: #C3C3C4; 
    border-radius: 15px;
}
  
::-webkit-scrollbar-thumb:hover {
    background: #9C9C9C;
}

input.form-control, textarea.form-control, select.form-control{
    font-size: 16px !important;
  }

input.form-control-xs, textarea.form-control-xs, select.form-control-xs{
    font-size: 14px !important;
    @media (max-width: 990px) {
      font-size: 12px !important;
    }
  }

input.form-control::placeholder, textarea.form-control::placeholder, select.form-control::placeholder{
    font-size: 16px !important;
  }

input.form-control-xs::placeholder, textarea.form-control-xs::placeholder, select.form-control-xs::placeholder{
    color: #c2c7d0 !important;
    font-size: 14px !important;
    @media (max-width: 990px) {
      font-size: 12px !important;
    }
  }

.pointer { 
    cursor: pointer;
}