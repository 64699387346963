.navbar {
	color: #fff;
	top: 0;
	z-index: 1000;
}

.navbar a {
	color: #f4f4f4f4;
}

.page-back a:hover {
	color: #f4f4f4f4;
}

.fixed-top {
	height: 50px !important;
}

.fa-circle {
	color: #dc0505;
	position: absolute;
	bottom: 14px;
	left: 25px;
}

.lang-and-profile {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: center;
	justify-content: center;
	align-items: center;
	width: 45px;
}
