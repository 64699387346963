.modal-bottom-information > .modal-content {
  max-height: 70vh;
  border-radius: 15px 15px 0px 0px;
  padding-bottom: env(safe-area-inset-bottom);
}
.modal-bottom-information {
  position: fixed;
  bottom: 0;
  left: 0;
  max-height: 70vh;
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(100%);
}

.Toastify__toast {
  color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Toastify__toast--error {
  background: #ffafaf;
}
