.stars {
	font-variation-settings: 'FILL' 1;
	font-size: 43px;
	z-index: 1;
	margin-top: 5px;
}

.stars:active {
	font-size: 38px;
}

.backdrop:active {
	width: 36px;
  height: 36px;
}

.icon-press {
	transition: font-size 30ms;
}

.icon-press:active {
	font-size: 16px;
	transition: font-size 30ms;
}

.nav-coupon {
	font-size: 16px;
}

.nav-coupon:active {
	font-size: 14px;
	transition: font-size 30ms;
}

.new_reject {
  position: absolute;
  font-size: 10px;
  border-radius: 40%;
  height: 15px;
  top: 8px;
  margin-left: 35px;
	z-index: 1;
}

.new_reject_icon {
	position: absolute;
	font-size: 8px;
	border-radius: 40%;
	height: 12px;
	margin-left: 35px;
	z-index: 1;
}