.number {
  width: 50px !important;
  height: 55px;
  border: #90a4c0 solid 1px;
  border-radius: 2px;
  margin-left: 10px;
}

.otp-input {
  display: flex;
  justify-content:center;
}